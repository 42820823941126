@font-face {
  font-family: 'sofiaregular';
  src: url('./fonts/sofia-regular-webfont.woff2') format('woff2'),
  url('./fonts/sofia-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

#home, #signIn {
  min-height: 100vh;
  height: 100%;
  overflow: auto;

}

/* ----- Sign In Page ----- */

#signIn {
  background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('./img/christmas-background.jpg');
}

#signIn div.ant-row {
  height: 100vh;
  max-height: 125vh;
}

#signInDisplay {
  height: 10em;
}

#signInTitle {
  font-size: 5em;
  text-align: center;
  color: #EA4630;
  font-family: 'sofiaregular', serif;
}

#signInDisplay div.ant-form-item-label {
  text-align: center;
}

#signInDisplay div.ant-form-item-label label {
  font-size: 2em;
  color: #EA4630;
  font-family: 'sofiaregular', serif;
}

/* ----- Home Page ----- */

#sideBar * {
  background-color: #BB2528;
}

#rules {
  font-size: 18px;
  margin-top: 1.5em;
  margin-bottom: 1em;
}

#sideBar .secondGreeting div {
  padding-top: 0;
  color: #ffffff;
}

#sideBar .greeting div {
  padding-bottom: 0;
  color: #ffffff;
}

#loggedInName {
  font-weight: bolder;
}

.reLogLabel {
  margin-top: 3em;
  font-weight: bolder;
}

#sideBar form.ant-form div.ant-select * {
  background-color: #fff;
}

#familyMemberContent div.ant-empty-image {
    display: none;
}

#familyMemberContent thead.ant-table-thead tr:first-child  th:first-child {
    padding: 0.75em 1.5em;
    background-color: #146B3A;
    color: #fff;
}

#familyMemberContent ul.ant-pagination {
  /*display: none;*/
}

/* ----- Rules Page ----- */


#screenshot {
  height: 400px;
  width: 100%;
}

#mainRules .rulesCols {
  padding-right: 20px;
  padding-top: 40px;
}

